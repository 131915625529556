import { Anchor, Page, PageContent, PageHeader, Tab, Tabs } from "grommet"
import { Network, Servers, Storage, VirtualMachine, FormPrevious, System } from "grommet-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Virtualization from "./components/Virtualization";
import CMDBStorage from "./components/Storage";
import Compute from "./components/Compute";
import NetworkComp from "./components/Network";
import OperatingSystem from "./components/OperatingSystem";

const Compliance = () => {
    const [index, setIndex] = useState<number>();
    const onActive = (nextIndex: number) => setIndex(nextIndex);
    const navigate = useNavigate();

    return (
        <Page fill background={''} pad={{ bottom: 'medium' }} height={{ min: '80vh' }}>
            <PageContent>
                <PageHeader title="Compliance" parent={<Anchor
                    icon={<FormPrevious />}
                    label={"Dashboard"}
                    href={"#"} // placeholder href to ensure it appears in tab flow
                    onClick={() => { navigate('/') }}
                />}
                    pad={{ vertical: '1.5rem' }} />
                <Tabs activeIndex={index} onActive={onActive} justify="start">
                    <Tab title="Compute" icon={<Servers />}>
                        <Compute />
                    </Tab>
                    <Tab title="Storage" icon={<Storage />}>
                        <CMDBStorage />
                    </Tab>
                    <Tab title="Network" icon={<Network />}>
                        <NetworkComp />
                    </Tab>
                    <Tab title="Virtualization" icon={<VirtualMachine />}>
                        <Virtualization />
                    </Tab>
                    <Tab title="Operating System" icon={<System />}>
                        <OperatingSystem />
                    </Tab>
                </Tabs>
            </PageContent>
        </Page>
    )
}

export default Compliance