import { Grommet } from "grommet";
import { hpe } from "grommet-theme-hpe";
// import { BrowserRouter as Router } from 'react-router-dom';
import { deepMerge } from "grommet/utils";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthProvider from '@/auth/AuthProvider';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';

const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER!,
    clientId: process.env.REACT_APP_OKTA_CLIENTID!,
    redirectUri: window.location.origin + '/login/callback'
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            retry: false,
        },
    },
});

const customTheme = {
    formField: {
        label: {
            size: "medium",
        },
    },
    checkBox: {
        // size: "15px",
        // gap: '0.6rem',
        // icon: {
        //     size: '16px'
        // }
    }
}

const AppProviders = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate()

    const triggerLogin = async () => {
        await oktaAuth.signInWithRedirect();
    };

    const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
        // setTimeout(() => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
        // }, 1000)
    };

    const customAuthHandler = async () => {
        const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
        if (!previousAuthState || !previousAuthState.isAuthenticated) {
            // App initialization stage
            await triggerLogin();
        } else {
            // Ask the user to trigger the login process during token autoRenew process
            //   setAuthRequiredModalOpen(true);
        }
    };

    return (
        <Grommet theme={deepMerge(hpe, customTheme)} themeMode={"light"}>
            <QueryClientProvider client={queryClient}>
                {/* <Router> */}
                <Security
                    oktaAuth={oktaAuth}
                    onAuthRequired={customAuthHandler}
                    restoreOriginalUri={restoreOriginalUri}
                >
                    <AuthProvider>
                        {children}
                    </AuthProvider>
                </Security>
                {/* </Router> */}
            </QueryClientProvider>
        </Grommet>
    )
}

export default AppProviders