import { useAuth } from "@/auth/AuthProvider";
import { Box, Grid, ResponsiveContext, Card, Text, Image } from "grommet";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ILoginForm } from '@/auth/types'
import LoginForm from "./LoginForm";

const Login = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (auth.user) {
      navigate("/")
    }
  }, [auth, navigate])

  const size = useContext(ResponsiveContext);

  // eslint-disable-next-line no-unused-vars
  const onSubmit = (value: ILoginForm) => {
    auth.loginAction(value)
  };

  return (
    <Box height={'100vh'} background={"url(./assets/images/login_bg.jpg)"} pad={{ horizontal: ["xsmall"].includes(size) ? "" : "1rem" }}>
      <Grid margin={{ horizontal: 'auto' }} columns={["xsmall", "small"].includes(size) ? "100%" : ['65%', 'flex']} fill align="center">
        {!["xsmall", "small"].includes(size) && <Box align="start" pad={{ start: '4.8rem' }}>
          <Text weight={500} color={'light-2'} size="5rem" margin={{ bottom: '0.8rem' }}>
            Telemetry Central
          </Text>
          <Text weight={500} color={'light-1'} size="2.5rem" margin={{ start: '6px' }}>
            HPE Managed Services
          </Text>
        </Box>
        }
        <Box margin={'auto'} width={{ width: '100%', max: '600px' }} pad={{ horizontal: ["xsmall"].includes(size) ? '1.4rem' : ["small"].includes(size) ? "5rem" : "2.5rem" }}>
          <Card elevation="medium" pad={{ bottom: '0.6rem', top: '0.5rem' }} background={"#ffffff"}>
            <Image src="./assets/images/hpe_logo.svg" margin={{ horizontal: ["xsmall"].includes(size) ? 'auto' : "0" }} width={["xsmall"].includes(size) ? '50%' : '40%'}></Image>

            {["xsmall", "small"].includes(size) && <Box align="center" margin={{ bottom: '1.5rem', top: '0rem' }}>
              <Text weight={500} color={'dark-1'} size="1.8rem" margin={{ bottom: '0.3rem' }}>
                HPE Managed Services
              </Text>
              <Text weight={600} color={'dark-1'} size="1.6rem">
                Telemetry Central
              </Text>
            </Box>}

            <LoginForm onSubmit={onSubmit} />
          </Card>
        </Box>
      </Grid >

    </Box >
  );
};

export default Login;
