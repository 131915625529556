import { Anchor, Box, Page, PageContent, PageHeader, Tab, Tabs } from "grommet"
import { Network, Servers, Storage, VirtualMachine, FormPrevious, CircleInformation, Aggregate } from "grommet-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Filters from "./components/Filters";
import Report from "./components/Report";
import { useGetReport } from "./api/query";
import { ReportingFilters } from "./types";
import moment from "moment";
import { MONTHS } from "@/utils/constants";
import { EmptyState } from "@/components/EmptyState";

const Reporting = () => {
    const navigate = useNavigate();
    const [body, setBody] = useState<ReportingFilters>()
    const [index, setIndex] = useState<number>();
    const onActive = (nextIndex: number) => setIndex(nextIndex);

    const {
        isLoading,
        // isError,
        error,
        // isSuccess,
        data,
        mutate,
    } = useGetReport(body!);

    const onSubmit = (value: ReportingFilters & { dateRange?: string[] }) => {

        let date = value?.date ? moment(value?.date).format("DD-MM-YYYY") : ""

        if (value.type === "Weekly") {
            date = moment(value?.dateRange?.[1]).add(1, 'day').format("DD-MM-YYYY")
        }

        if (value.type === "Monthly") {
            let month: any = MONTHS.indexOf(value.month!) + 1
            month = month < 9 ? '0' + (+month + 1) : +month + 1
            date = `01-${month}-${new Date().getFullYear()}`
        }

        const _body = {
            date: date,
            type: value.type,
            kind: value.kind,
            product: ""
        }

        if (value?.product) {
            _body.product = value.product
        }

        setBody(_body)
    };

    useEffect(() => {
        if (body)
            mutate()
    }, [body, mutate])

    const downloadPDF = () => {
        // Create a Blob object from the PDF data
        const blob = new Blob([data], { type: 'application/pdf' });

        // Create a URL for the Blob object
        const url = URL.createObjectURL(blob);

        // Create a temporary anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = `${body?.kind}-${body?.type}-${body?.date}.pdf`;

        // Append the anchor element to the document body
        document.body.appendChild(a);

        // Trigger a click event on the anchor element
        a.click();

        // Remove the anchor element from the document body
        document.body.removeChild(a);

        // Revoke the URL to release the object URL
        URL.revokeObjectURL(url);
    };

    return (
        <Page fill background={''} pad={{ bottom: 'medium' }} height={{ min: '80vh' }}>
            <PageContent>
                <PageHeader title="Reporting" pad={{ vertical: '1.5rem' }} parent={
                    <Anchor
                        icon={<FormPrevious />}
                        label={"Dashboard"}
                        href={"#"} // placeholder href to ensure it appears in tab flow
                        onClick={() => { navigate('/') }}
                    />
                }
                />

                <Tabs activeIndex={index} onActive={onActive} justify="start">
                    <Tab title="Consolidated" icon={<Aggregate />} key="Consolidated">
                        <Box margin={{ top: '8rem' }}>
                            <EmptyState title='No data available' align='center' description='Once data is available, it will be displayed here.' icon={<CircleInformation size='xlarge' />} level={"2"} />
                        </Box>
                    </Tab>
                    <Tab title="Compute" icon={<Servers />} key="Compute">
                        <Filters onSubmit={onSubmit} type={"Compute"} key="Compute" products={["OneView"]} />
                        <Report data={body?.kind === "Compute" && data} downloadPDF={downloadPDF} isLoading={isLoading} error={error} />
                    </Tab>
                    <Tab title="Network" icon={<Network />} key="Network">
                        <Box margin={{ top: '8rem' }}>
                            <EmptyState title='No data available' align='center' description='Once data is available, it will be displayed here.' icon={<CircleInformation size='xlarge' />} level={"2"} />
                        </Box>
                    </Tab>
                    <Tab title="Virtualization" icon={<VirtualMachine />} key="Virtualization">
                        <Filters onSubmit={onSubmit} type={"Virtualization"} key="Virtualization" products={["vSphere"]} />
                        <Report data={body?.kind === "Virtualization" && data} downloadPDF={downloadPDF} isLoading={isLoading} error={error} />
                    </Tab>
                    <Tab title="Storage" icon={<Storage />} key="Storage">
                        <Filters onSubmit={onSubmit} type={"Storage"} key="Storage" products={["Nimble"]} />
                        <Report data={body?.kind === "Storage" && data} downloadPDF={downloadPDF} isLoading={isLoading} error={error} />
                    </Tab>
                </Tabs>

            </PageContent>
        </Page>
    )
}

export default Reporting