// import SessionExpirePopup from "@/components/SessionExpirePopup";
import axios from "axios";
// import ReactDOM from "react-dom";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

// Function to render the custom component
// const renderExpiredSessionMessage = () => {
//   const div = document.createElement("div");
//   document.body.appendChild(div);
//   ReactDOM.render(<SessionExpirePopup />, div);
// };

export function updateAuthorizationHeader(token: string) {
  if (!token) return;
  // Clone the current headers object to avoid mutation
  const headers = { ...apiClient.defaults.headers };
  // Set the Authorization header with the provided token
  headers["Authorization"] = `Bearer ${token}`;
  // Update apiClient defaults with the new headers
  apiClient.defaults.headers = headers;
}

// Add a response interceptor
// apiClient.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     // Check if the error is due to token expiration
//     if (error.response && (error.response.status === 401 || error.response.status === 403)) {
//       // Render the custom component to inform the user about the expired session
//       // renderExpiredSessionMessage();
//     }
//     return Promise.reject(error);
//   }
// );
