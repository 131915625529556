import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { getDataCenter, getDataStores, getClusters, getVMs, getEsxiServers, getCmdbStorageData, getCmdbProduct } from "./services";
import { ApiResponse } from "@/types";
import { IClusters, IDataCenters, IDataStores, IEsxiServers, IProduct, IStorage, IVms } from "../types";

export const useGetDataCenters = () =>
    useQuery<ApiResponse<IDataCenters[]>, AxiosError>(["datacenters"], () => getDataCenter());

export const useGetDataStores = () =>
    useQuery<ApiResponse<IDataStores[]>, AxiosError>(["data-stores"], () => getDataStores());

export const useGetClusters = () =>
    useQuery<ApiResponse<IClusters[]>, AxiosError>(["clusters"], () => getClusters());

export const useGetVMs = () =>
    useQuery<ApiResponse<IVms[]>, AxiosError>(["vms"], () => getVMs());

export const useEsxiServers = () =>
    useQuery<ApiResponse<IEsxiServers[]>, AxiosError>(["exsi-servers"], () => getEsxiServers());

export const useCmdbStorage = (product: string) =>
    useQuery<ApiResponse<IStorage>, AxiosError>(["cmdb-storage"], () => getCmdbStorageData(product), {
        enabled: false, // (!) handle refetchs manually
    });

export const useCmdbProduct = (product_type: string) =>
    useQuery<ApiResponse<IProduct[]>, AxiosError>(["cmdb-product", product_type], () => getCmdbProduct(product_type));