import { Box, ColumnConfig, DataTable, ResponsiveContext, Spinner, Text } from "grommet"
import { useContext, useState } from "react";
import { useGetComputeCompliance } from "../api";
import { ComputeCompliance, Recommendation } from "../types";
import { convertSecondstoMinHr } from "@/utils/appUtils";
import { StatusCritical, CircleAlert, Alert, Tooltip } from "grommet-icons";
import { CardWithNumber } from "@/components/Card";
import { EmptyState } from "@/components/EmptyState";

function getIcon(status: string | undefined, forTiles: boolean): import("react").ReactNode {
    switch (status?.toLowerCase()) {
        // case "green": return <StatusGoodSmall color={status} />
        case "critical": return <StatusCritical size={forTiles ? "1.3rem" : '1.1rem'} color={!forTiles ? "#FC6161" : "white"} />
        case "recommended": return <Alert size={forTiles ? "1.2rem" : '1.1rem'} color={!forTiles ? "#FFBC44" : "white"} />
        case "optional": return <CircleAlert size={forTiles ? "1.3rem" : '1.1rem'} color={!forTiles ? "#00E8CF" : "white"} />
        default:
            break;
    }
}

function getColor(status: string | undefined): string {
    switch (status?.toLowerCase()) {
        case "critical": return "#FC6161"
        case "recommended": return "#FFBC44"
        case "optional": return "#00E8CF"
        default: return "red"
    }
}

const columns: ColumnConfig<ComputeCompliance>[] = [
    {
        property: 'hardwareName',
        header: 'Hardware',
        render: (datum: ComputeCompliance) => <Text>{datum.hardwareName || "--"}</Text>,
        verticalAlign: 'top',
    },
    {
        property: 'hardwareModel',
        header: 'Model',
        verticalAlign: 'top',
        render: (datum: ComputeCompliance) => <Text>{datum.hardwareModel || "--"}</Text>,
    },
    {
        property: 'baselineVersion',
        header: 'Firmware Bundle',
        verticalAlign: 'top',
        render: (datum: ComputeCompliance) => <Text>{datum.baselineVersion || "--"}</Text>,
    },
    {
        property: 'updateCategory',
        header: 'Update Category',
        verticalAlign: 'top',
        render: (datum: ComputeCompliance) => (<Box>
            {Object.entries(datum.compliance).map((x: any) => {
                if (x[1] > 0)
                    return (
                        <Box key={x[0]} flex direction="row" gap={'small'} align="center">
                            {getIcon(x[0], false)}
                            <Text>{x[1]}</Text>
                            <Text style={{ textTransform: 'capitalize' }}>{x[0]}</Text>
                        </Box>
                    )
                else return null;
            })}
        </Box>),
    },
    {
        property: 'installTime',
        header: 'Estimated Update Time',
        verticalAlign: 'top',
        render: (datum: ComputeCompliance) => <Text>{convertSecondstoMinHr(datum.installTime) || "--"}</Text>,
    }
];

const columns2 = [
    {
        property: 'upgradeRequirement',
        header: 'Update Category',
        render: (datum: Recommendation) => <Text>{datum.upgradeRequirement || "--"}</Text>,
    },
    {
        property: 'componentName',
        header: 'Name',
        primary: true,
        render: (datum: Recommendation) => <Text>{datum.componentName + " " + datum?.componentLocation || "--"}</Text>,
    },
    {
        property: 'installedVersion',
        header: 'Installed',
        render: (datum: Recommendation) => <Text>{datum.installedVersion || "--"}</Text>,
    },
    {
        property: 'baselineVersion',
        header: 'Available',
        render: (datum: Recommendation) => <Text>{datum.baselineVersion || "--"}</Text>,
    }
]

const Compute = () => {
    const size = useContext(ResponsiveContext);
    const [selectedItem, selectItem] = useState<string>("" as string)

    const { isLoading, data } = useGetComputeCompliance()

    function handleSelect(item: string): void {
        if (selectedItem === item)
            selectItem("")
        else
            selectItem(item)
    }

    let _data = data?.data || []

    let compliance = {
        Critical: 0,
        Recommended: 0,
        Optional: 0,
    }

    _data.forEach((item) => {
        compliance.Critical += item.compliance.critical
        compliance.Optional += item.compliance.optional
        compliance.Recommended += item.compliance.recommended
    })

    if (selectedItem)
        _data = _data.filter((item: any) => {
            console.log(item.compliance[selectedItem.toLowerCase()])
            return item.compliance[selectedItem.toLowerCase()] > 0
        })

    return (
        <Box margin={{ top: '2.2rem' }} gap={"medium"}>
            <Box flex justify="center" align="center" fill>
                <Box flex wrap direction="row" justify="center" gap={"medium"} margin={{ bottom: 'medium' }}>
                    {Object.entries(compliance).map((x: any, index: number) =>
                        <CardWithNumber key={x[0]} selectedItem={selectedItem} title={x[0]} value={x[1]} color={getColor(x[0])} onClick={handleSelect} icon={getIcon(x[0], true)} />
                    )}
                </Box>
                {isLoading ?
                    <Spinner />
                    :
                    _data?.length > 0 ?
                        <DataTable
                            aria-describedby="compute-compliance"
                            key={selectedItem}
                            data={_data}
                            columns={columns}
                            fill
                            pad="small"
                            rowDetails={(row) => {
                                if (row.recommendations) {
                                    return (
                                        <Box margin={{ horizontal: 'xlarge' }}>
                                            <DataTable
                                                aria-describedby="Details"
                                                data={row.recommendations || []}
                                                columns={columns2}
                                                background={'background-back'}
                                            />
                                        </Box>)
                                }

                            }}
                            paginate={{
                                border: 'top',
                                direction: 'row',
                                fill: 'horizontal',
                                flex: false,
                                justify: !['xsmall', 'small'].includes(size) ? 'end' : 'center',
                                pad: { top: 'xsmall' },
                            }}
                            step={10}
                            sortable
                        />
                        :
                        <Box margin={{ top: '5rem' }}>
                            <EmptyState align='center' level={"3"} title='No Data Available' description={""} icon={<Tooltip size='xlarge' />} />
                        </Box>}
            </Box>
        </Box>
    )
}

export default Compute