import { Route, Routes } from 'react-router-dom';

import Login from '@/features/Login';
import Dashboard from '@/features/Dashboard';
import PrivateRoute from './PrivateRoute';
import CIDiscovery from '@/features/CiDiscovery';
import Reporting from '@/features/Reporting';
import { LoginCallback } from '@okta/okta-react';
import Compliance from '@/features/Complaince';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route element={<PrivateRoute />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/ci-discovery" element={<CIDiscovery />} />
                <Route path="/reporting" element={<Reporting />} />
                <Route path="/compliance" element={<Compliance />} />
            </Route>
        </Routes>
    );
};