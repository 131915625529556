import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { getComputeCompliance, getOSCompliance } from "./services";
import { ApiResponse } from "@/types";
import { ComputeCompliance, OSComplianceRes } from "../types";

export const useGetComputeCompliance = () =>
    useQuery<ApiResponse<ComputeCompliance[]>, AxiosError>(["compute_compliance"], () => getComputeCompliance());


export const useGetOSCompliance = () =>
    useQuery<ApiResponse<OSComplianceRes>, AxiosError>(["os_compliance"], () => getOSCompliance());
