import { EmptyState } from '@/components/EmptyState'
import { Box } from 'grommet'
import { CircleInformation } from 'grommet-icons'

const Compute = () => (
    <Box margin={{ top: '8rem' }}>
        <EmptyState title='No data available' align='center' description='Once data is available, it will be displayed here.' icon={<CircleInformation size='xlarge' />} level={"2"} />
    </Box>
)

export default Compute