import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import MainHeader from './Header';
import { Main, Text, Box } from 'grommet';
import SessionExpirePopup from './SessionExpirePopup';
import { useAuth } from '@/auth/AuthProvider';

type LayoutProps = {
    children: ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }) => {

    const authTimer = useRef<NodeJS.Timer>();
    const auth = useAuth();
    const [timeLeft, setTimeLeft] = useState<number>(-1)

    useEffect(() => {
        if (auth.user) {
            let expireTime: any = localStorage.getItem("expires_in") || 0

            if (authTimer?.current)
                clearInterval(authTimer.current)

            authTimer.current = setInterval(() => {
                let remainingTime = +expireTime - new Date().getTime()
                if (remainingTime <= 0) {
                    clearInterval(authTimer.current)
                }

                if (+remainingTime < 10000) {
                    setTimeLeft(Math.round(remainingTime / 1000))
                }
            }, 1000)
        }

        return () => { clearInterval(authTimer.current) }
    }, [auth]);

    const handleLogout = () => {
        clearInterval(authTimer.current)
        auth.logOut()
    }

    return (
        <Main fill >
            <MainHeader handleLogout={handleLogout} />
            {(timeLeft >= 0 || timeLeft < -1) &&
                <SessionExpirePopup timeLeft={timeLeft} handleLogout={handleLogout} />
            }
            {children}
            <Box width={'100%'} background={'background-back'} pad={{ vertical: 'small', horizontal: 'medium' }}>
                <Text weight={'lighter'} size='small' color={'#000000'}>
                    © Copyright 2024 Hewlett Packard Enterprise Development LP
                </Text>
            </Box>
        </Main>
    )
}

export default Layout