import { Box, Button, Heading, Layer, Paragraph } from 'grommet';
import { Alert } from 'grommet-icons';
import { FC, useEffect } from 'react';

type SessionExpirePopupProps = {
  timeLeft: number;
  handleLogout: () => void
};

const SessionExpirePopup: FC<SessionExpirePopupProps> = ({ timeLeft, handleLogout }) => {

  useEffect(() => {
    // console.log({ timeLeft })
    if (timeLeft <= 0) {
      handleLogout()
    }
  }, [timeLeft, handleLogout])

  return (
    <Layer>
      <Box pad="medium" >
        <Box direction="column" align="center" gap="small" width={'medium'}>
          <Box gap="medium" align='center'>
            <Box >
              <Alert color='red' size='4rem' />
            </Box>
            <Heading level={2} margin="none" >
              Session Expired
            </Heading>

            <Paragraph margin="none">
              You will be redirected to login page.
            </Paragraph>
          </Box>
        </Box>

        <Box margin={{ top: 'medium' }}>
          <Button primary label={timeLeft > 0 ? `Redirect (${timeLeft})` : 'Redirecting to login'} onClick={handleLogout} />
        </Box>
      </Box>
    </Layer>
  )
}

export default SessionExpirePopup