import { useEffect, useRef, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import type { PDFDocumentProxy } from 'pdfjs-dist';
import { Box, Button, Spinner, } from 'grommet';
import { EmptyState } from '@/components/EmptyState';
import { Tooltip, StatusWarning } from 'grommet-icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type ReportProps = {
    downloadPDF: () => void,
    data: any,
    isLoading: boolean,
    error: any
}

const Report: React.FC<ReportProps> = ({ downloadPDF, data, isLoading, error }) => {
    const [numPages, setNumPages] = useState<number>();
    const [lateMsg, showLateMsg] = useState<boolean>(false);

    const timer: any = useRef()

    useEffect(() => {
        if (isLoading) {
            clearTimeout(timer.current)
            timer.current = setTimeout(() => {
                showLateMsg(true)
            }, 8000);
        } else {
            showLateMsg(false)
            clearTimeout(timer.current)
        }

        return () => { clearTimeout(timer.current); }
    }, [isLoading])

    function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
        setNumPages(nextNumPages);
    }

    if (isLoading) return <Box fill flex direction="row" justify="center" margin={{ top: '8rem' }}>
        <EmptyState align='center' level={"2"} title='Generating Report' description={lateMsg ? "It looks like this is taking longer than expected. Thank you for your patience" : ""} icon={<Spinner />} />
    </Box>

    if (error) return <Box fill flex direction="row" justify="center" margin={{ top: '8rem' }}>
        {/* <Text size='large'>{error?.message}</Text> */}
        <EmptyState align='center' level={"2"} title='Failed to Generate Report' description={error} icon={<StatusWarning size='xxlarge' />} />
    </Box>

    if (!data) return <Box fill flex direction="row" justify="center" margin={{ top: '8rem' }}>
        {/* <Text size='large'>Select Filters to Generate Report</Text> */}
        <EmptyState align='center' level={"2"} title='Choose Filters to Generate Report' description={""} icon={<Tooltip size='xlarge' />} />
    </Box>

    return (
        <Box >
            <Box
                align={'end'}
                margin={{ top: 'large', bottom: 'medium' }}
            >
                {numPages && <Button primary label="Download Report" onClick={() => downloadPDF()} />}
            </Box>

            <Box fill>
                <Document className="pdf-doc" file={data} loading={<Box fill flex direction="row" justify="center" margin={{ top: 'xlarge' }}><Spinner /></Box>} onLoadSuccess={onDocumentLoadSuccess} >
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={Math.min(window.innerWidth, 1024)}
                        />
                    ))}
                </Document>
            </Box>

        </Box>
    )
}

export default Report