import React from 'react';
import { Box, Heading, Paragraph } from 'grommet';

type EmptyStateProps = {
    actions?: React.ReactNode,
    align: 'start' | 'center',
    description: string,
    icon: React.ReactNode,
    level: any,
    title: string,
}

export const EmptyState: React.FC<EmptyStateProps> = ({
    actions,
    align = 'center',
    description,
    icon,
    level,
    title,
}) => {

    return (
        <Box gap="medium" align={align} flex={false}>
            {icon}
            <Box align={align} gap="xsmall">
                <Heading margin="none" level={level}>
                    {title}
                </Heading>
                <Paragraph margin="none" textAlign={align}>
                    {description}
                </Paragraph>
            </Box>

            {actions}
        </Box>
    );
};
