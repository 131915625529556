import { apiClient } from "@/helpers/axiosClient";
import { ReportingFilters } from "../types";

export async function getReport(body: ReportingFilters) {
    try {
        const response = await apiClient.post(`/v1/reporting`, body, {
            responseType: 'blob'
        });

        return response && response.data;
    } catch (err: any) {
        if (err.response && err.response.data) {
            // Error response is in blob format
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onload = () => {
                    const errorMessage = reader.result as string;
                    reject(JSON.parse(errorMessage).message);
                };
                reader.onerror = () => {
                    reject('Error occurred while processing error response.');
                };
                reader.readAsText(err.response.data);
            });
        } else {
            // No error response data available
            throw 'Error occurred while fetching report.';
        }
    }
}