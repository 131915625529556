import { Box, Button, Card, CardBody, ColumnConfig, DataTable, Heading, Layer, List, NameValueList, NameValuePair, ResponsiveContext, Spinner, Text } from "grommet"
import { ReactNode, useContext, useState } from "react";
import { useGetOSCompliance } from "../api";
import { OSCompliance, OSComplianceDetail } from "../types";
import { StatusCritical, StatusGood, SubtractCircle, CircleInformation, Tooltip, Next, Previous, Aggregate } from "grommet-icons";
import { CardWithNumber } from "@/components/Card";
import moment from "moment";
import { EmptyState } from "@/components/EmptyState";
import DoughnutChart from "@/components/DoughnutChart";
import { convertToTitleCase } from "@/utils/appUtils";

const OsDetails = ({ onClose, data, getColor, getIcon }: {
    onClose: () => void, data: OSCompliance, getColor: (status: string) => string, getIcon: (status: string, forTiles: boolean) => ReactNode
}) => {
    const size = useContext(ResponsiveContext);
    const [selectedItem, selectItem] = useState<string>("Total")


    const columns: ColumnConfig<OSComplianceDetail>[] = [

        {
            property: 'rule_name',
            header: 'Rule Name',
            verticalAlign: 'top',
            render: (datum: OSComplianceDetail) => <Text>{datum.rule_name || "--"}</Text>,
        },
        {
            property: 'status',
            header: 'Status',
            verticalAlign: 'top',
            render: (datum: OSComplianceDetail) => (
                <Box flex direction="row" gap={'small'} align="center">
                    {getIcon(datum?.status, false)}
                    <Text style={{ textTransform: 'capitalize' }}>{datum?.status || "--"}</Text>
                </Box>),
        },
        {
            property: 'last_scanned',
            header: 'Last Scanned',
            verticalAlign: 'top',
            render: (datum: OSComplianceDetail) => <Text>{datum?.last_scanned ? moment(datum?.last_scanned).format("DD MMM YYYY h:mm a") : "--"}</Text>,
        }
    ];


    function handleSelect(item: string): void {
        if (selectedItem === item)
            selectItem("")
        else
            selectItem(item)
    }

    let _data: OSComplianceDetail[] = data?.data || []

    if (selectedItem !== "Total")
        _data = _data?.filter((item: OSComplianceDetail) => {
            return item.status === selectedItem
        })

    const total = Object.values(data?.statusCounts).reduce((total, item) => total += item, 0)

    return (
        <Layer full>
            <Box pad={{ horizontal: "xlarge" }} fill>
                <Box margin={{ top: '1rem' }} direction="row" justify="between" align="center">
                    <Heading>
                        {data?.ip_address}
                    </Heading>
                    <Button secondary icon={<Previous />} label="Back" onClick={onClose} />
                </Box>
                <Box fill>
                    <Box margin={{ vertical: 'medium' }} justify="between" pad={{ horizontal: 'large', vertical: 'medium' }} align="center" direction="row">
                        <Box gap={"medium"}>
                            <Card background={"background-back"} pad={"medium"} >
                                <NameValueList valueProps={{ width: ['auto', 'small'] as any }}>
                                    {data && Object.entries(data).map(([name, value]: any) => {
                                        if (["statusCounts", "data"].includes(name)) {
                                            return
                                        }
                                        return (
                                            <NameValuePair key={name} name={convertToTitleCase(name)}>
                                                {value || "--"}
                                            </NameValuePair>
                                        );
                                    })}
                                </NameValueList>
                            </Card>

                            <Box direction="row" justify="center" gap={"small"} align="center">
                                <CardWithNumber selectedItem={selectedItem} title={"Total"} value={total} color={getColor("total")} onClick={handleSelect} icon={getIcon("total", true)} />
                                {data?.statusCounts && Object.entries(data?.statusCounts).map((x: any, index: number) =>
                                    <CardWithNumber key={x[0]} selectedItem={selectedItem} title={x[0]} value={x[1]} color={getColor(x[0])} onClick={handleSelect} icon={getIcon(x[0], true)} />
                                )}
                            </Box>
                        </Box>
                        <Box >
                            <DoughnutChart labels={Object.keys(data?.statusCounts)} data={Object.values(data?.statusCounts)} colors={Object.keys(data?.statusCounts)?.map((x: string) => getColor(x))} showLegend={false} showTitle={true} />
                        </Box>
                    </Box>
                    {/* 
                    <Box direction="row" justify="center" gap={"small"} align="center">
                        {data?.statusCounts && Object.entries(data?.statusCounts).map((x: any, index: number) =>
                            <CardWithNumber key={x[0]} selectedItem={selectedItem} title={x[0]} value={x[1]} color={getColor(x[0])} onClick={handleSelect} icon={getIcon(x[0], true)} />
                        )}
                    </Box> */}

                    <Box>
                        {_data?.length > 0 ?
                            <DataTable
                                aria-describedby="os-compliance"
                                key={selectedItem}
                                data={_data || []}
                                columns={columns}
                                fill
                                pad="small"
                                paginate={{
                                    border: 'top',
                                    direction: 'row',
                                    fill: 'horizontal',
                                    flex: false,
                                    justify: !['xsmall', 'small'].includes(size) ? 'end' : 'center',
                                }}
                                step={10}
                                sortable
                            />
                            :
                            <Box margin={{ top: '5rem' }}>
                                <EmptyState align='center' level={"3"} title='No Data Available' description={""} icon={<Tooltip size='xlarge' />} />
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Layer>
    )
}

export default OsDetails