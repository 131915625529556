import { Box, Page, PageContent, PageHeader } from "grommet"
import Content from "./components/Content"

const Dashboard = () => {
    return (
        <Box background="" fill pad={{ bottom: 'medium' }}>
            <Page fill>
                <PageContent>
                    <PageHeader title="Dashboard" pad={{ top: '1.5rem' }} />
                    <Content />
                </PageContent>
            </Page>
        </Box>
    )
}

export default Dashboard