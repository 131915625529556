import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
            text: 'Chart.js Bar Chart',
        },
        datalabels: {
            display: true,
            color: "white",
        }
    },
};

const labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];



const BarChart = ({ color = "#008567", values }: { color?: any, values: any }) => {
    const data = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: values,
                backgroundColor: color,
            }
        ],
    };
    return <Bar options={options} data={data} />;
}

export default BarChart
