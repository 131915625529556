import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from 'react';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);



type DoughnutChartProps = {
    data?: number[],
    labels?: string[],
    colors?: string[],
    showLegend?: boolean,
    showTitle?: boolean,
};


const DoughnutChart: React.FC<DoughnutChartProps> = ({ data = [12, 19, 3, 5], labels = ['Failed Nodes', 'Skipped Nodes', 'Passed Nodes', 'Waived Nodes'], colors, showLegend = true, showTitle = false }) => {

    const options: ChartOptions<'doughnut'> = {
        // responsive: true,
        // maintainAspectRatio: false,
        plugins: {
            legend: {
                display: showLegend,
            },
            title: {
                display: showTitle,
                text: 'Total: ' + data.reduce((total, dataPoint) => total + dataPoint, 0),
                position: 'top',
            },
            datalabels: {
                display: true,
                color: "white",
            }
        },
    };

    const _data = {
        labels: labels.map(x => x.charAt(0).toUpperCase()
            + x.slice(1)),
        datasets: [
            {
                data: data,
                backgroundColor: colors || [
                    '#FC6161',
                    '#FFBC44',
                    '#00E8CF',
                    '#00739D',
                ],
                borderColor: colors || [
                    '#FC6161',
                    '#FFBC44',
                    '#00E8CF',
                    '#00739D',
                ],
                borderWidth: 1,
            },
        ],

    };
    return <Doughnut data={_data} options={options} style={{ margin: 'auto' }} />;
}

export default DoughnutChart
