import { apiClient } from "@/helpers/axiosClient";

export async function getDataCenter() {
    const response = await apiClient.get(`/v1/ci-discovery/vcenter/datacenters`);
    return response && response.data;
}

export async function getDataStores() {
    const response = await apiClient.get(`/v1/ci-discovery/vcenter/datastores`);
    return response && response.data;
}

export async function getClusters() {
    const response = await apiClient.get(`/v1/ci-discovery/vcenter/clusters`);
    return response && response.data;
}

export async function getVMs() {
    const response = await apiClient.get(`/v1/ci-discovery/vcenter/vms`);
    return response && response.data;
}

export async function getEsxiServers() {
    const response = await apiClient.get(`/v1/ci-discovery/vcenter/esxservers`);
    return response && response.data;
}

export async function getCmdbStorageData(product: string) {
    const response = await apiClient.get(`/v1/ci-discovery/storage?product=${product}`);
    return response && response.data;
}

export async function getCmdbProduct(product_type: string) {
    const response = await apiClient.get(`/v1/ci-discovery/products?product_type=${product_type}`);
    return response && response.data;
}