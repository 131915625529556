import React from 'react';
import { Box, Form, FormField, DateInput, Select, Button } from 'grommet';
import moment from 'moment';
import { MONTHS } from '@/utils/constants';
import { ReportingFilters } from '../types';

type FilterTypes = {
    onSubmit: (value: any) => void,
    type: string,
    products: string[]
}

const Filters: React.FC<FilterTypes> = ({ onSubmit, type, products }) => {
    const formModel = {
        kind: type,
        type: '',
        date: '',
        month: '',
        dateRange: [] as any[],
        product: ''
    }

    const [formData, setFormData] = React.useState<ReportingFilters & { dateRange?: string[] }>(formModel);

    const onChange = (value: ReportingFilters & { dateRange?: string[] }) => {
        if (value.type !== formData.type)
            setFormData({ ...formModel, type: value.type });
        else
            setFormData(value);
    };

    const handleReset = () => {
        setFormData({ ...formModel })
    }

    // const firstdate = moment().startOf('month').format('MM-DD-YYYY');
    const firstdate = moment('07/11/2024').format('MM-DD-YYYY');
    const yesterday = moment().startOf("day").subtract(1, "days").format('MM-DD-YYYY');
    const months = MONTHS.slice(0, new Date().getMonth())

    const today = moment();
    const notMondays: string[] = [] //array contains all dates which are not monday from 1st of current month to yesterday
    const mondays: string[] = []

    const firstDayOfLastMonth = moment().subtract(1, 'months').startOf('month').format('MM-DD-YYYY');

    // Iterate through dates from the 1st of the month to yesterday
    for (let date = moment(moment().subtract(1, 'month')).startOf('month'); date.isBefore(today, 'day'); date.add(1, 'days')) {
        // Check if the date is not a Monday
        if (date.day() !== 1) {
            notMondays.push(date.format('YYYY-MM-DD'));
        } else mondays.push(date.format('YYYY-MM-DD'));
    }

    if (mondays.length > 0)
        notMondays.push(mondays.pop()!)

    return (
        <Box margin={{ top: '1rem' }}>
            <Form messages={{
                required: 'This is a required field.',
            }}
                data-testid="filters-form"
                onReset={handleReset}
                onChange={onChange}
                onSubmit={({ value }: { value: any }) => onSubmit({ ...value, kind: type })}
            >
                <Box flex direction='row-responsive' gap={"medium"} align='center'>
                    {type !== "Consolidated" &&
                        <FormField
                            name="product"
                            htmlFor="product"
                            label="Product"
                            required
                        >
                            <Select options={products}
                                id="product"
                                name="product"
                                placeholder="Select Product"
                            />
                        </FormField>}

                    <FormField
                        name="type"
                        htmlFor="type"
                        label="Duration"
                        required
                    >
                        <Select options={["Daily", "Weekly", "Monthly"]}
                            placeholder="Select Duration"
                            id="type"
                            name="type"
                            disabled={["Weekly", "Monthly"]}
                        />
                    </FormField>

                    {formData.type === "Daily" &&
                        <FormField
                            name="date"
                            htmlFor="date"
                            label="Date"
                            required
                        >
                            <DateInput
                                aria-label="date"
                                name="date"
                                placeholder='Select Date'
                                id="date"
                                calendarProps={{ bounds: [firstdate, yesterday] }}
                                format="dd/mm/yyyy"
                            />
                        </FormField>
                    }

                    {formData.type === "Weekly" &&
                        <FormField
                            name="dateRange"
                            htmlFor="dateRange"
                            label="Week"
                            required
                        >
                            <DateInput
                                name="dateRange"
                                placeholder='Select Week Start Date'
                                id="dateRange"
                                value={formData.dateRange}
                                format="dd/mm/yyyy-dd/mm/yyyy"
                                calendarProps={{
                                    disabled: notMondays,
                                    daysOfWeek: true,
                                    bounds: [firstDayOfLastMonth, yesterday],
                                    firstDayOfWeek: 1,
                                    showAdjacentDays: false,
                                    onSelect: (date) => {
                                        if (Array.isArray(date)) {
                                            if (formData.dateRange && date[0][0] === formData.dateRange[0])
                                                setFormData({ ...formData, dateRange: [date[0][1], moment(date[0][1]).add(6, 'days').toISOString()] })
                                            else
                                                setFormData({ ...formData, dateRange: [date[0][0], moment(date[0][0]).add(6, 'days').toISOString()] })
                                        }
                                        else {
                                            setFormData({ ...formData, dateRange: [date, moment(date).add(6, 'days').toISOString()] })
                                        }
                                    }
                                }}

                            />
                        </FormField>
                    }

                    {formData.type === "Monthly" &&
                        <FormField
                            name="month"
                            htmlFor="month"
                            label="Month"
                            required
                        >
                            <Select options={months}
                                id="month"
                                placeholder="Select Month"
                                name="month"
                            />
                        </FormField>
                    }

                    <Box direction="row" gap="medium" pad={{ top: 'medium' }} margin={{ left: 'auto' }}>
                        <Button label="Apply filters" primary type='submit' />
                        <Button label="Reset filters" secondary type='reset' />
                    </Box>
                </Box>
            </Form>
        </Box>
    )
}

export default Filters