import { apiClient } from "@/helpers/axiosClient";

export async function getComputeCompliance() {
    const response = await apiClient.get(`/v1/compliance/compute`);
    return response && response.data;
}

export async function getOSCompliance() {
    const response = await apiClient.get(`/v1/compliance/os`);
    return response && response.data;
}
