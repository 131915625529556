import { AppRoutes } from '@/routes';

import './App.css'
import AppProviders from "./AppProviders";

function App() {

  return (
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  )
}

export default App
